import { headerMenu } from '../../constant'
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  components: {
    Close: () => import(/* webpackChunkName: "sidebar-icon" */ '@/components/icons/Close'),
    User: () => import(/* webpackChunkName: "sidebar-icon" */ '@/components/icons/User'),
    G2Academy: () => import(/* webpackChunkName: "sidebar-icon" */ '@/components/icons/G2Academy'),
    Exit: () => import(/* webpackChunkName: "sidebar-icon" */ '@/components/icons/Exit'),
    GlobeOutline: () => import(/* webpackChunkName: "sidebar-icon" */ '@/components/icons/GlobeOutline'),
    Accordion: () => import(/* webpackChunkName: "accordion" */ '@/components/accordion/Accordion')
  },
  props: {
    isDashboardPage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      navigation: headerMenu.navigation,
      menuDashboard: {
        main: [
          {
            text: 'Beranda',
            url: '/'
          },
          {
            text: 'Profile Saya',
            url: '/user/profile'
          }
        ],
        dashboard: [
          {
            text: 'Lihat semua pertemuan',
            anchor: 'allSchedule'
          },
          {
            text: 'Message Board',
            anchor: 'message'
          },
          {
            text: 'Pengajar',
            anchor: 'instructor'
          },
          {
            text: 'Class Resources',
            anchor: 'resources'
          }
        ]
      },
      loginItem: {
        student: [
          {
            text: 'Kelas Saya',
            url: '/user/my-classes',
            borderBottom: false
          },
          {
            text: 'Account',
            url: '/user/account',
            borderBottom: true
          },
          {
            text: 'Logout',
            borderBottom: false
          }
        ],
        prakerja: [
          {
            text: 'Profile',
            url: '/profile'
          },
          {
            text: 'My Class',
            url: '/my-class'
          },
          {
            text: 'My Referral',
            url: '/my-referral-code'
          },
          {
            text: 'Logout'
          }
        ]
      }
    }
  },
  watch: {
    showSurvey(value) {
      if (value) {
        const item = {
          text: 'Class Survey',
          anchor: 'survey'
        }
        this.menuDashboard.dashboard.splice(2, 0, item)
      }
    }
  },
  computed: {
    ...mapGetters('common', ['isNavOpen']),
    ...mapGetters('auth', ['isLoggedIn', 'userData']),
    ...mapGetters('student', ['surveyVisible']),
    userRoles() {
      return this.userData.role_id
    },
    loginMenu() {
      return this.userRoles === 'STUDENT' ? this.loginItem.student : this.loginItem.prakerja
    },
    studentName() {
      return localStorage.getItem('user_name')
    },
    photoProfile() {
      return localStorage.getItem('photo')
    },
    showSurvey() {
      return this.surveyVisible
    }
  },
  methods: {
    ...mapMutations('common', ['toggleSideBar']),
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('auth', ['logoutPrakerja', 'removeUserData']),
    ...mapActions('student', ['toAnchor']),
    showMultilanguangeModal() {
      this.$emit('showMultilanguangeModal')
    },
    toggle() {
      this.toggleSideBar()
    },
    setAnchor(anchor) {
      this.toggle()
      this.toAnchor({ anchor })
    },
    capitalizeFirst(str) {
      if (!str) return
      const lowerCase = str.toLowerCase()
      return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1)
    },

    clickMenu(item, index) {
      if (!item.title) this.$router.push({ name: 'Programs' })
      const programMenu = this.navigation[0].submenu.menu
      programMenu[index].active = !programMenu[index].active
      const filterActive = programMenu.map((menu, indexMenu) => {
        return { ...menu, active: programMenu[index].active }
      })
    },
    closeProgramMenu() {
      this.navigation[0].active = false
      const programMenu = this.navigation[0].submenu.menu
      this.navigation[0].submenu.menu = programMenu.map((menu) => {
        return { ...menu, active: false }
      })
    },
    doLogout() {
      const cleanUserData = new Promise((resolve, reject) => {
        resolve(this.removeUserData())
      })
      cleanUserData.then(() => {
        this.$router.push({ name: 'Home' })
      })
    }
  }
}
