import { render, staticRenderFns } from "./TheSidebar.vue?vue&type=template&id=bb30ff8e&scoped=true"
import script from "./js/the-sidebar.js?vue&type=script&lang=js&external"
export * from "./js/the-sidebar.js?vue&type=script&lang=js&external"
import style0 from "./TheSidebar.vue?vue&type=style&index=0&id=bb30ff8e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb30ff8e",
  null
  
)

export default component.exports